/* line 36, scss/80-themes/Saunders/60-components/agent-item.scss */
.c-agent-item {
	background-color: transparent;
	line-height: 1.5em;
	position: relative;
}

/* line 41, scss/80-themes/Saunders/60-components/agent-item.scss */
.c-agent-item:hover {
	background-color: transparent;
}

/* line 50, scss/80-themes/Saunders/60-components/agent-item.scss */
.c-agent-item .agent-photo::before {
	z-index: 3;
	content: "";
	position: absolute;
	width: "";
	height: 100%;
	background-color: "";
}

@media only screen and (max-width: 47.999em) {
	/* line 63, scss/80-themes/Saunders/60-components/agent-item.scss */
	.agent-item__tools {
		border-top: 1px solid rgb(var(--color-global-positive));
	}
}

@media only screen and (min-width: 60em) and (max-width: 79.999em) {
	/* line 63, scss/80-themes/Saunders/60-components/agent-item.scss */
	.agent-item__tools {
		border-top: 1px solid rgb(var(--color-global-positive));
	}
}

/* line 72, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__tools--border-top {
	border-top: 1px solid rgb(var(--color-global-positive));
	background: rgb(var(--color-global-widget));
}

/* line 78, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__tool {
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.5em;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .agent-item__tool {
	text-align: right;
}

@media only screen and (max-width: 47.999em) {
	/* line 90, scss/80-themes/Saunders/60-components/agent-item.scss */
	.agent-item__tools:not(.o-list-wide) .agent-item__tool {
		margin-right: 20px;
		display: inline-block;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agent-item__tools:not(.o-list-wide) .agent-item__tool {
		margin-right: initial;
		margin-left: 20px;
	}
}

@media only screen and (max-width: 47.999em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agent-item__tools:not(.o-list-wide) .agent-item__tool {
		margin-right: 0;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 95, scss/80-themes/Saunders/60-components/agent-item.scss */
	.agent-item__tools:not(.o-list-wide) .agent-item__tool:last-child {
		margin-right: 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agent-item__tools:not(.o-list-wide) .agent-item__tool:last-child {
		margin-right: initial;
		margin-left: 0;
	}
}

@media only screen and (max-width: 47.999em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agent-item__tools:not(.o-list-wide) .agent-item__tool:last-child {
		margin-right: 0;
	}
}

@media only screen and (min-width: 60em) and (max-width: 79.999em) {
	/* line 90, scss/80-themes/Saunders/60-components/agent-item.scss */
	.agent-item__tools:not(.o-list-wide) .agent-item__tool {
		margin-right: 20px;
		display: inline-block;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agent-item__tools:not(.o-list-wide) .agent-item__tool {
		margin-right: initial;
		margin-left: 20px;
	}
}

@media only screen and (min-width: 60em) and (max-width: 79.999em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agent-item__tools:not(.o-list-wide) .agent-item__tool {
		margin-right: 0;
	}
}

@media only screen and (min-width: 60em) and (max-width: 79.999em) {
	/* line 104, scss/80-themes/Saunders/60-components/agent-item.scss */
	.agent-item__tools:not(.o-list-wide) .agent-item__tool:last-child {
		margin-right: 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agent-item__tools:not(.o-list-wide) .agent-item__tool:last-child {
		margin-right: initial;
		margin-left: 0;
	}
}

@media only screen and (min-width: 60em) and (max-width: 79.999em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agent-item__tools:not(.o-list-wide) .agent-item__tool:last-child {
		margin-right: 0;
	}
}

/* line 110, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__tool--center {
	text-align: center;
}

/* line 113, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__tool--center:last-child {
	text-align: right;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .agent-item__tool--center:last-child {
	text-align: left;
}

/* line 119, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__link {
	padding: 10px 0;
}

/* line 123, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__icon {
	padding: 5px 10px 5px 0;
	color: rgb(var(--color-global-secondary));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .agent-item__icon {
	padding: 5px 0 5px 10px;
}

/* line 128, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__container {
	padding: 0 10px;
	padding-top: 10px;
	box-sizing: border-box;
}

/* line 134, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__title {
	padding-top: 10px;
}

/* line 142, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__description {
	margin-top: 5px;
}

/* line 146, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__photo-container {
	text-align: center;
}

/* line 150, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__photo {
	background: rgb(var(--color-global-border));
}

/* line 154, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__details {
	border: 1px solid rgb(var(--color-global-border));
	border-top: 0;
	display: flex;
	flex-direction: column;
}

@media screen and (min-width: 0\0) {
	/* line 161, scss/80-themes/Saunders/60-components/agent-item.scss */
	.agent-item__details[style] {
		height: 20px;
	}
}

/* line 166, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__details .agent-item__container {
	flex: 1 1 auto;
	position: relative;
}

/* line 171, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__details .agent-item__tools {
	flex: 0 0 auto;
	display: table;
	padding-bottom: 10px;
}

/* line 178, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__office {
	display: block;
	margin: 10px 0;
	color: rgb(var(--color-global-primary));
}

/* line 184, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__title, .agent-item__description, .agent-item__office {
	line-height: 1em;
}

/* line 194, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__name {
	color: rgb(var(--color-global-highlight));
}

/* line 198, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__role {
	margin-bottom: 10px;
}

/* line 202, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__tool--right, .agent-item__phone-container--right {
	text-align: right;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .agent-item__tool--right, .is-rtl .agent-item__phone-container--right {
	text-align: left;
}

/* line 207, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__phone-container--right {
	white-space: nowrap;
}

/* line 211, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__address, .agent-item__languages {
	font-size: 0.85em;
}

/* line 216, scss/80-themes/Saunders/60-components/agent-item.scss */
.agent-item__languages {
	margin-top: 10px;
}

/*# sourceMappingURL=../../../../true */